import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IUserState, UserStatus } from './user-state.interface';
import { userFK } from './user.reducer';

const selectFeature = createFeatureSelector<IUserState>(userFK);

export const selectIsUserLoading = createSelector(
  selectFeature,
  (state: IUserState) => state.isLoading.info
);

export const selectIsUserLoggingIn = createSelector(
  selectFeature,
  (state: IUserState) => state.isLoading.login
);

export const selectIsUserLoggingOut = createSelector(
  selectFeature,
  (state: IUserState) => state.isLoading.logout
);

export const selectIsUserEditing = createSelector(
  selectFeature,
  (state: IUserState) => state.isLoading.edit
);

export const selectUserEditingError = createSelector(
  selectFeature,
  (state: IUserState) => state.error.edit
);

export const selectUserInfo = createSelector(
  selectFeature,
  (state: IUserState) => state.user
);

export const selectUserError = createSelector(
  selectFeature,
  (state: IUserState) => state.error
);

export const selectUserSubscriptions = createSelector(
  selectFeature,
  (state: IUserState) => state.subs
);

export const selectUserTrial = createSelector(
  selectFeature,
  (state: IUserState) =>
    state.subs[0]?.trial && {
      status: state.subs[0].trial,
      endDate: state.subs[0].trialEndDate,
      startDate: state.subs[0].trialStartDate,
    }
);

export const selectUserStatus = createSelector(
  selectFeature,
  (state: IUserState) => {
    if (!state.user) {
      return UserStatus.Unauthorized;
    }

    if (state.subs[0]) {
      if (
        state.subs[0].trial &&
        state.subs[0].product.find(p => !!p.purchasedInfo)
      ) {
        return UserStatus.SubscriberWithTrial;
      }

      if (
        state.subs[0].trial === 'NEW' ||
        state.subs[0].trial === 'NEW_ADMIN'
      ) {
        return UserStatus.TrialNew;
      }

      if (state.subs[0].trial === 'OLD') {
        return UserStatus.TrialOld;
      }
    }

    if (state.subs[0] && state.subs[0].product.find(p => !!p.purchasedInfo)) {
      return UserStatus.Subscriber;
    } else {
      return UserStatus.Base;
    }
  }
);

export const selectEmailAuthCheck = createSelector(
  selectFeature,
  (state: IUserState) => state.emailAuthCheck
);

export const selectEmailInCheck = createSelector(
  selectFeature,
  (state: IUserState) => state.isLoading.emailAuthCheck
);

export const selectIsUserInWhiteList = createSelector(
  selectFeature,
  (state: IUserState) => state.user?.isWhiteList
);
