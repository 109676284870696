import { createAction, props } from '@ngrx/store';
import { ILogin } from '@src/app/shared/models/interfaces/auth/login.interface';
import { IEditUser } from '@src/app/shared/models/interfaces/edit-user.interface';
import { ILoginUser } from '@src/app/shared/models/interfaces/login-user.interface';
import { Subscription } from '@src/app/shared/models/interfaces/subscription.interface';

import { IUserCheckedEmail } from './user-state.interface';

export const getUser = createAction('[App] Get User');

export const getUserSuccess = createAction(
  '[App] Get User with success',
  props<{ userInfo: ILoginUser }>()
);

export const getUserFailure = createAction(
  '[App] Get User with failure',
  props<{ error: string }>()
);

export const getUserSubscriptions = createAction(
  '[App] Get User subscriptions'
);

export const getUserSubscriptionsSuccess = createAction(
  '[App] Get User subscriptions with success',
  props<{ userSubs: Subscription[] }>()
);

export const getUserSubscriptionsFailure = createAction(
  '[App] Get User subscriptions with failure',
  props<{ error: string }>()
);

export const loginUser = createAction(
  '[App] Login User',
  props<{ loginData: ILogin }>()
);

export const loginUserSuccess = createAction(
  '[App] User logged in successfully',
  props<{ userLogin: ILoginUser }>()
);

export const loginUserFailure = createAction(
  '[App] Error occurred during user login'
);

export const logoutUser = createAction('[App] Logout User');

export const logoutUserSuccess = createAction('[App] Logout User with success');

export const logoutUserFailure = createAction(
  '[App] Logout User with failure',
  props<{ error: string }>()
);

export const unsetUser = createAction('[App] Remove User');

export const editUser = createAction(
  '[App] Edit User',
  props<{ editedInfo: IEditUser; userId: string }>()
);

export const editUserSuccess = createAction(
  '[App] Edit User with success',
  props<{ userInfo: ILoginUser }>()
);

export const editUserFailure = createAction(
  '[App] Edit User with failure',
  props<{ error: string }>()
);

export const checkUserEmailOnAuth = createAction(
  '[App] Check User Email on Auth',
  props<{ email: string }>()
);

export const checkUserEmailOnAuthSuccess = createAction(
  '[App] Check User Email on Auth with success',
  props<{ emailCheck: IUserCheckedEmail }>()
);

export const checkUserEmailOnAuthFailure = createAction(
  '[App] Check User Email on Auth with failure',
  props<{ error: string }>()
);

export const checkUserEmailReset = createAction('[App] Check User Email Reset');
