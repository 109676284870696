import { createReducer, on } from '@ngrx/store';

import { IUserState } from './user-state.interface';
import {
  checkUserEmailOnAuth,
  checkUserEmailOnAuthFailure,
  checkUserEmailOnAuthSuccess,
  checkUserEmailReset,
  editUser,
  editUserFailure,
  editUserSuccess,
  getUser,
  getUserFailure,
  getUserSubscriptions,
  getUserSubscriptionsFailure,
  getUserSubscriptionsSuccess,
  getUserSuccess,
  loginUser,
  loginUserFailure,
  loginUserSuccess,
  logoutUser,
  logoutUserFailure,
  logoutUserSuccess,
  unsetUser,
} from './user.actions';

export const userFK = 'userFK';

export const initialState: IUserState = {
  error: {
    edit: null,
    info: null,
    logout: null,
    subs: null,
    emailAuthCheck: null,
  },
  isLoading: {
    edit: false,
    info: false,
    login: false,
    logout: false,
    subs: false,
    emailAuthCheck: false,
  },
  subs: [],
  user: null,
  emailAuthCheck: null,
};

export const userReducer = createReducer(
  initialState,

  on(
    getUser,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        info: true,
      },
    })
  ),

  on(getUserSuccess, (state, action): IUserState => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        info: false,
      },
      user: action.userInfo,
    };
  }),

  on(
    getUserFailure,
    (state, action): IUserState => ({
      ...state,
      error: {
        ...state.error,
        info: action.error,
      },
      isLoading: {
        ...state.isLoading,
        info: true,
      },
    })
  ),

  on(
    logoutUser,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        logout: true,
      },
    })
  ),

  on(logoutUserSuccess, (state): IUserState => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        logout: false,
      },
      user: null,
    };
  }),

  on(
    logoutUserFailure,
    (state, action): IUserState => ({
      ...state,
      error: {
        ...state.error,
        logout: action.error,
      },
      isLoading: {
        ...state.isLoading,
        logout: false,
      },
    })
  ),

  on(
    loginUser,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        login: true,
      },
    })
  ),

  on(loginUserSuccess, (state, action): IUserState => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        login: false,
      },
      user: action.userLogin,
    };
  }),

  on(
    loginUserFailure,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        login: false,
      },
    })
  ),

  on(
    unsetUser,
    (state): IUserState => ({
      ...state,
      user: null,
    })
  ),

  on(
    editUser,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        edit: true,
      },
    })
  ),

  on(editUserSuccess, (state, action): IUserState => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        edit: false,
      },
      user: { ...state.user, ...action.userInfo },
    };
  }),

  on(
    editUserFailure,
    (state, action): IUserState => ({
      ...state,
      error: {
        ...state.error,
        edit: action.error,
      },
      isLoading: {
        ...state.isLoading,
        edit: false,
      },
    })
  ),

  on(
    getUserSubscriptions,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        subs: true,
      },
    })
  ),

  on(getUserSubscriptionsSuccess, (state, action): IUserState => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        subs: false,
      },
      subs: action.userSubs,
    };
  }),

  on(
    getUserSubscriptionsFailure,
    (state, action): IUserState => ({
      ...state,
      error: {
        ...state.error,
        subs: action.error,
      },
      isLoading: {
        ...state.isLoading,
        subs: true,
      },
    })
  ),

  on(
    checkUserEmailOnAuth,
    (state): IUserState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        emailAuthCheck: true,
      },
    })
  ),

  on(
    checkUserEmailOnAuthSuccess,
    (state, action): IUserState => ({
      ...state,
      emailAuthCheck: action.emailCheck,
      isLoading: {
        ...state.isLoading,
        emailAuthCheck: false,
      },
    })
  ),

  on(
    checkUserEmailOnAuthFailure,
    (state, action): IUserState => ({
      ...state,
      error: {
        ...state.error,
        emailAuthCheck: action.error,
      },
      isLoading: {
        ...state.isLoading,
        emailAuthCheck: false,
      },
    })
  ),

  on(
    checkUserEmailReset,
    (state): IUserState => ({
      ...state,
      emailAuthCheck: null,
    })
  )
);
