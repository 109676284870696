import { ILoginUser } from '@src/app/shared/models/interfaces/login-user.interface';
import {
  Subscription,
  TrialStatus,
} from '@src/app/shared/models/interfaces/subscription.interface';

export interface IUserState {
  error: {
    edit: string;
    info: string;
    logout: string;
    subs: string;
    emailAuthCheck: string;
  };
  isLoading: {
    edit: boolean;
    info: boolean;
    login: boolean;
    logout: boolean;
    subs: boolean;
    emailAuthCheck: boolean;
  };
  subs: Subscription[];
  user: ILoginUser;
  emailAuthCheck: IUserCheckedEmail;
}

export interface IUserTrial {
  endDate: number;
  status: TrialStatus;
}

export interface IUserCheckedEmail {
  enabled: boolean;
  exists: boolean;
}

export enum UserStatus {
  Unauthorized = 'unauthorized',
  Base = 'base',
  TrialNew = 'trial-new',
  TrialOld = 'trial-old',
  Subscriber = 'subscriber',
  SubscriberWithTrial = 'subscriber-with-trial',
}
